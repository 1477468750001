import React from 'react';
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div className="landing">

      {/* Banner */}
      <section id="banner">
        <h2>Succursales à Québec et en Estrie</h2>
        <h3>L'équipe de Réno-Pro Entrepreneur Général offre des services complets de construction et d'excavation à une clientèle variée.</h3>
        <ul className="actions">
          <li><Link to="/contact" className="button special big">Prenez rendez-vous</Link></li>
        </ul>
        <h4>Estimation gratuite</h4>
      </section>

      {/* One */}
      <section id="one" className="wrapper style1">
        <div className="container 75%">
          <div className="row 200%">
            <div className="6u 12u$(medium)">
              <header className="major">
                <h2>Commercial</h2>
                <p>Modification professionelle de bâtiments commerciaux</p>
              </header>
            </div>
            <div className="6u$ 12u$(medium)">
              <img src={require("./images/commercial.jpg")} alt="" className="12u" />
            </div>
          </div>
          <div className="row 200%">
            <div className="6u 12u$(medium)">
              <header className="major">
                <h2>Industriel</h2>
                <p>Agrandissement et modification de bâtiments industriels durant les opérations de l’industrie</p>
              </header>
            </div>
            <div className="6u$ 12u$(medium)">
              <img src={require("./images/industriel.jpg")} alt="" className="12u" />
            </div>
          </div>
          <div className="row 200%">
            <div className="6u 12u$(medium)">
              <header className="major">
                <h2>Résidentiel</h2>
                <p>Réparation et agrandissement de bâtiments résidentiels</p>
              </header>
            </div>
            <div className="6u$ 12u$(medium)">
              <img src={require("./images/residentiel.jpg")} alt="" className="12u" />
            </div>
          </div>
        </div>
      </section>

      {/* Four */}
      <section id="four" className="wrapper style3 special">
        <div className="container">
          <header className="major special">
            <h2>Des résultats professionnels</h2>
            <p>Une équipe qui a de l'expérience</p>
          </header>
        </div>
      </section>

      {/* Three */}
      <section id="three" className="wrapper style1">
        <div className="container">
          <div className="feature-grid">
            <div className="feature">
              <div className="image rounded">
                <img src={require("./images/experience.jpg")} alt="" />
              </div>
              <div className="content">
                <header>
                  <h4>L'expérience</h4>
                  <p>dont vous avez besoin</p>
                </header>
                <p>Vous avez un projet de construction, modification ou rénovation? Nous possédons l'expérience pour effectuer les travaux dont vous avez besoin. Visionnez nos galeries photos pour découvrir nos projets passés, puis contactez-nous pour prendre rendez-vous avec nos experts.</p>
              </div>
            </div>
            <div className="feature">
              <div className="image rounded">
                <img src={require("./images/environnement.jpg")} alt="" />
              </div>
              <div className="content">
                <header>
                  <h4>La protection de l'environnement</h4>
                  <p>au cœur de nos services</p>
                </header>
                <p>Nos méthodes de travail sont basées sur le respect de l'environnement. Un maximum de matériaux et de débris de chantier est recyclé. Tous nos travaux sont réalisés dans les règles de l'art, selon les normes en vigueur.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
