import React from 'react';
import { Link } from 'react-router-dom'

const firstImageNumber = 1
const lastImageNumber = 17

const imageRequires = Array.from(Array(lastImageNumber - firstImageNumber + 1).keys())
  .map(imageNumber => require("./sub-tiles/residentiel-" + (imageNumber + firstImageNumber) + ".jpg"));

export default function Residentiel() {
  return (
    <section id="main" className="wrapper">
      <div className="container">

        <header className="major special">
          <h2>Des projets résidentiels de qualité</h2>
        </header>

        <div className="box alt">
          <div className="row 50% uniform">
            <div className="12u$"><span className="image fit"><img src={require("./high-banner.jpg")} alt="Commercial" className="commercial-main-image" /></span></div>
          </div>
        </div>

        <h1>Votre satisfaction, notre priorité</h1>
        <p>L’équipe de Réno-Pro Entrepreneur Général effectue de nombreux travaux de construction, modification et rénovation résidentielle. Notre équipe travaille toujours avec la satisfaction des clients en tête : nos projets sont faits sur mesure selon vos besoins. Vous pouvez ici visionner une galerie de projets résidentiels créés par nos spécialistes. N’hésitez pas à <Link to="/contact">nous appeler</Link> pour en apprendre plus et pour discuter de votre projet avec nous!</p>

        <hr />

        <div className="box alt">
          <div className="row 50% uniform">
            {imageRequires.map((imageRequire, index) => {
              let outterClassName = "4u"
              if (index % 3 === 2) {
                outterClassName = "4u$"
              }
              return (<div className={outterClassName}><span className="image fit"><img src={imageRequire} alt="Commercial" className="low-fixed-tile-image"></img></span></div>)
            })
            }
          </div>
        </div>

      </div>
    </section>
  );
}
