import React from 'react';

export default function Contact() {
  return (
    <div>
      <section id="main" className="wrapper">
        <div className="container">

          <header className="major special">
            <h2>Contactez-nous</h2>
          </header>

          <div className="box alt">
          <div className="row 50% uniform">
            <div className="12u$"><span className="image fit"><img src={require("./high-banner.jpg")} alt="Commercial" className="commercial-main-image" /></span></div>
          </div>
          </div>

          <p>L’équipe de Réno-Pro Entrepreneur Général vous offre ses services de construction, de rénovation et de modification de bâtiments. Nous nous spécialisons également en excavation et possédons l’équipement nécessaire pour effectuer ces travaux de façon sécuritaire, selon les normes. Nous desservons une clientèle résidentielle, commerciale et industrielle, dans la région de Québec et dans l'Estrie. Contactez-nous par téléphone dès aujourd’hui pour parler à nos spécialistes. Vous pouvez remplir le formulaire sur cette page ou nous joindre directement par courriel.</p>
          <hr />

          <section className="wrapper style1">
          <div className="container">
            <div className="feature-grid">
              <div className="feature">
                <div className="image rounded">
                  <img src={require("./isabelle.jpg")} alt="" />
                </div>
                <div className="content">
                  <header>
                    <h3>Isabelle Crousset</h3>
                  </header>
                  <h5>Bureau</h5>
                  <p><a href="tel:418-933-3961">418-933-3961</a></p>
                  <h5>Courriel</h5>
                  <p><a href="mailto:info@reno-pro.ca">info@reno-pro.ca</a></p>
                </div>
              </div>
              <div className="feature">
                <div className="image rounded">
                  <img src={require("./gilles.jpg")} alt="" />
                </div>
                <div className="content">
                  <header>
                    <h3>Gilles LeBel</h3>
                  </header>
                  <h5>Cellulaire</h5>
                  <p><a href="tel:418-933-6940">418-933-6940</a></p>
                  <h5>Courriel</h5>
                  <p><a href="mailto:info@reno-pro.ca">info@reno-pro.ca</a></p>
                </div>
              </div>
            </div>
          </div>
          </section>

        </div>
      </section>
    </div>
  );
}
