import React from 'react';
import { Link } from 'react-router-dom'

const firstImageNumber = 1
const lastImageNumber = 4

const imageRequires = Array.from(Array(lastImageNumber - firstImageNumber + 1).keys())
  .map(imageNumber => require("./sub-tiles/industriel-" + (imageNumber + firstImageNumber) + ".jpg"));

export default function Industriel() {
  return (
    <section id="main" className="wrapper">
      <div className="container">

        <header className="major special">
          <h2>Découvrez nos projets industriels</h2>
        </header>

        <div className="box alt">
          <div className="row 50% uniform">
            <div className="12u$"><span className="image fit"><img src={require("./high-banner.jpg")} alt="Commercial" className="industrial-main-image" /></span></div>
          </div>
        </div>

        <h1>Travaux selon les opérations de l’industrie</h1>
        <p>Réno-Pro Entrepreneur Général offre ses services complets de construction, modification et rénovation de bâtiments à sa clientèle industrielle. Nos travaux sont effectués durant les opérations de l’industrie, et nous utilisons des techniques d’assemblage préusinées. Découvrez les projets industriels que nous avons réalisés. <Link to="/contact">Contactez-nous</Link> partout dans la région de Québec et de l'Estrie pour discuter de vos projets avec nos spécialistes!</p>

        <hr />

        <div className="box alt">
          <div className="row 50% uniform">
            {imageRequires.map((imageRequire, index) => {
              let outterClassName = "4u"
              if (index % 3 === 2) {
                outterClassName = "4u$"
              }
              return (<div className={outterClassName}><span className="image fit"><img src={imageRequire} alt="Commercial" className="low-fixed-tile-image"></img></span></div>)
            })
            }
          </div>
        </div>

      </div>
    </section>
  );
}
