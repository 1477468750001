import React from 'react';
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div>
      < footer id="footer" >
          <ul className="copyright">
            <li>146, chemin Labonté, Lambton, QC G0M 1H0</li>
            <li><a href="tel:418-933-3961">418-933-3961</a></li>
            <li><a href="mailto:info@reno-pro.ca">info@reno-pro.ca</a></li>
            <li><Link to="/contact"><button>Contactez-Nous</button></Link></li>
          </ul>
      </footer >

      {/* Scripts */}
      <script src="assets/js/jquery.min.js" />
      <script src="assets/js/skel.min.js" />
      <script src="assets/js/util.js" />
      <script src="assets/js/main.js" />
    </div>
  );
}
