import React from 'react';
import { Link } from 'react-router-dom'

const firstImageNumber = 0
const lastImageNumber = 17

const imageRequires = Array.from(Array(lastImageNumber - firstImageNumber + 1), (_, key) => key)
  .map(imageNumber => require("./sub-tiles/Commercial-" + (imageNumber + firstImageNumber) + ".jpg"));

export default function Commercial() {
  return (
    <section id="main" className="wrapper">
      <div className="container">

        <header className="major special">
          <h2>Construction, modification et rénovation commerciale</h2>
        </header>

        <div className="box alt">
          <div className="row 50% uniform">
            <div className="12u$"><span className="image fit"><img src={require("./high-banner.jpg")} alt="Commercial" className="commercial-main-image" /></span></div>
          </div>
        </div>

        <h1>L'expérience dont vous avez besoin</h1>
        <p>L'entreprise Réno-Pro Entrepreneur Général est spécialisée dans la modification de bâtiments commerciaux. Depuis notre création en 1993, nous connaissons une croissance continue grâce à la qualité de nos réalisations. La fierté et le travail soigné de nos employés nous ont apporté la confiance de notre clientèle.</p>
        <p>Visionnez notre galerie sur cette page pour découvrir nos projets passés, puis <Link to="/contact">contactez-nous</Link> pour prendre rendez-vous avec nos experts.</p>

        <hr />

        <div className="box alt">
          <div className="row 50% uniform">
            {imageRequires.map((imageRequire, index) => {
              let outterClassName = "4u"
              if (index % 3 === 2) {
                outterClassName = "4u$"
              }
              return (<div className={outterClassName}><span className="image fit"><img src={imageRequire} alt="Commercial" className="low-fixed-tile-image"></img></span></div>)
            })
            }
          </div>
        </div>

      </div>
    </section>
  );
}
