import React from 'react';
import { NavLink } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'

export default function Header() {
  if (window.innerWidth < 736) {
    return (
      <div id="header">
        <img src={require("./images/logo.png")} alt="logo" className="logo" />
        <Menu id="nav" disableAutoFocus>
          <ul>
            <li><NavLink exact to="/" activeClassName="selected">Accueil</NavLink></li>
            <li><NavLink exact to="/excavation" activeClassName="selected">Excavation</NavLink></li>
            <li><NavLink exact to="/commercial" activeClassName="selected">Commercial</NavLink></li>
            <li><NavLink exact to="/residentiel" activeClassName="selected">Résidentiel</NavLink></li>
            <li><NavLink exact to="/industriel" activeClassName="selected">Industriel</NavLink></li>
            <li><NavLink exact to="/contact" activeClassName="selected">Contactez-Nous</NavLink></li>
          </ul>
        </Menu>
      </div>
    )
  } else {
    return (
      <div id="header">
        <img src={require("./images/logo.png")} alt="logo" className="logo" />
        <nav id="nav">
          <ul>
            <li><NavLink exact to="/" activeClassName="selected">Accueil</NavLink></li>
            <li><NavLink exact to="/excavation" activeClassName="selected">Excavation</NavLink></li>
            <li><NavLink exact to="/commercial" activeClassName="selected">Commercial</NavLink></li>
            <li><NavLink exact to="/residentiel" activeClassName="selected">Résidentiel</NavLink></li>
            <li><NavLink exact to="/industriel" activeClassName="selected">Industriel</NavLink></li>
            <li><NavLink exact to="/contact" activeClassName="selected">Contactez-Nous</NavLink></li>
          </ul>
        </nav>
      </div>
    )
  }
}