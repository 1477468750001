import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom'
import ScrollToTop from './scrollToTop/ScrollToTop.jsx'
import Header from '../header/Header'
import Home from '../home/Home'
import Excavation from '../excavation/Excavation'
import Commercial from '../commercial/Commercial'
import Residentiel from '../residentiel/Residentiel'
import Industriel from '../industriel/Industriel'
import Contact from '../contact/Contact'
import Footer from '../footer/Footer'

export default function App() {
  return (
    <Router>
      <div>
        <ScrollToTop>

          <Header />

          <Route exact path="/" component={Home} />
          <Route path="/excavation" component={Excavation} />
          <Route path="/commercial" component={Commercial} />
          <Route path="/residentiel" component={Residentiel} />
          <Route path="/industriel" component={Industriel} />
          <Route path="/contact" component={Contact} />

          <Footer />
        </ ScrollToTop>
      </div>
    </Router>
  );
}
