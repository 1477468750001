import ReactDOM from 'react-dom';
import App from './app/App';

import './shared/assets/css/main.css'
import './shared/assets/css/burger.css'

import * as serviceWorker from './serviceWorker';

ReactDOM.render(App(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
