import React from 'react'
import * as Router from 'react-router-dom'

const ScrollToTop = ({ children, location: { pathname } }) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return children;
};

export default Router.withRouter(ScrollToTop);