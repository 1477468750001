import React from 'react'
import { Link } from 'react-router-dom'

const firstImageNumber = 1
const lastImageNumber = 11

const imageRequires = Array.from(Array(lastImageNumber - firstImageNumber + 1).keys())
  .map(imageNumber => require("./sub-pictures/excavation-" + (imageNumber + firstImageNumber) + ".jpg"));

export default function Excavation() {
  return (
    <div>
      <section id="main" className="wrapper">
        <div className="container">
          <header className="major special">
            <h2>Excavation</h2>
            <p>Des travaux d’excavation sécuritaires</p>
          </header>

          <div className="box alt">
            <div className="row 50% uniform">
              <div className="12u$"><span className="image fit"><img src={require("./high-banner.jpg")} alt="Excavation" className="excavation-main-image" /></span></div>
            </div>
          </div>

          <div className="row">
            <div className="6u 12u$(xsmall)">
              <div>
                <h4 className="align-center excavation-subtitle">La base des fondations</h4>
              </div>
              <div className="align-center">
                <p>Pour tous vos besoins en excavation, faites confiance à l’équipe de Réno-Pro Entrepreneur Général Inc. Nous possédons l’équipement et les certifications pour effectuer ces types de travaux de façon professionnelle, en toute sécurité.</p>
                <p>L’excavation est utilisée en construction afin de mettre en place les fondations d’un bâtiment. Il est donc essentiel qu’elle soit effectuée avec minutie par des professionnels. N’hésitez pas à <Link to="">contacter notre équipe!</Link></p>
              </div>
            </div>
            <div className="6u$ 12u$(xsmall)">

            <iframe title="video excavation (youtube)" width={560} height={315} src="https://www.youtube.com/embed/FgkTBRVRIrY" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />

            </div>
          </div>

          <div className="box alt">
            <div className="row 50% uniform">
              {imageRequires.map((imageRequire, index) => {
                let outterClassName = "4u"
                if (index % 3 === 2) {
                  outterClassName = "4u$"
                }
                return (<div className={outterClassName}><span className="image fit"><img src={imageRequire} alt="Excavation" className="low-fixed-tile-image"></img></span></div>)
              })
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}